.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  background-color: #3f51b5 !important;
  color: white !important;
  position: fixed;
}
.leaflet-control-zoom-out {
  border-top: 2px white !important;
  margin-top: 30px;
}

.leaflet-control-zoom {
  border: 0 !important;
}

.leaflet-control-layers {
  top: 100px;
  font-size: "9.6px";
}

@media only screen and (min-width: 900px) {
  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out {
    font-size: 20px !important;
    width: 25px !important;
    height: 25px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-bottom: 0.2rem;
    background-color: #3f51b5 !important;
    color: white !important;
  }
  .leaflet-control-zoom-out {
    border-top: 1px solid !important;
    border-color: rgba(255, 255, 255, 0.5);
    margin-top: 25px;
  }

  .leaflet-control-zoom {
    margin-left: 0.75rem !important;
    margin-top: 0.75rem !important;
    border: 0 !important;
  }
  .leaflet-marker-icon {
    width: 30px !important;
    height: 55px !important;
  }
  .leaflet-homeIcon {
    height: 50px !important;
    width: 50px !important;
  }
  .leaflet-control-layers {
    top: 100px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 1344px) {
  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out {
    font-size: 25px !important;
    width: 35px !important;
    height: 35px !important;
    padding-bottom: 0.5rem;
  }
  .leaflet-control-zoom-out {
    border-top: 2px solid !important;
    margin-top: 35px;
  }

  .leaflet-control-zoom {
    margin-left: 1.25rem !important;
    margin-top: 1.25rem !important;
    border: 0 !important;
  }
  .leaflet-marker-icon {
    width: 30px !important;
    height: 55px !important;
  }

  .leaflet-homeIcon {
    height: 50px !important;
    width: 50px !important;
  }
  .leaflet-control-layers {
    top: 110px;
    font-size: 14px;
  }
}

@media only screen and (min-width: 1920px) {
  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out {
    font-size: 30px !important;
    width: 40px !important;
    height: 40px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-bottom: 0.5rem;
    background-color: #3f51b5 !important;
    color: white !important;
  }
  .leaflet-control-zoom-out {
    border-top: 2px solid !important;
    margin-top: 40px;
  }

  .leaflet-control-zoom {
    margin-left: 1.5rem !important;
    margin-top: 1.5rem !important;
    border: 0 !important;
  }
  .leaflet-marker-icon {
    width: 37px !important;
    height: 62px !important;
  }
  .leaflet-homeIcon {
    height: 50px !important;
    width: 50px !important;
  }
}
